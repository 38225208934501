<template>
  <div class="loginmain">
    <a-form id="formLogin" class="user-layout-login" ref="formLogin">
      <a-tabs
        :activeKey="customActiveKey"
        :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }"
      >
        <a-tab-pane key="tab1" tab="账号密码登录">
          <h2>欢迎登陆</h2>
          <a-alert
            v-if="isLoginError"
            type="error"
            showIcon
            style="margin-bottom: 24px"
            message="账户或密码错误(admin/ant.design)"
          />
          <a-form-item>
            <a-input
              size="large"
              type="text"
              v-model="username"
              autocompleted="off"
            >
              <a-icon
                slot="prefix"
                type="user"
                :style="{ color: 'rgba(0,0,0,.25)' }"
              />
            </a-input>
          </a-form-item>

          <a-form-item>
            <!--  autocomplete="false"
                    -->
            <a-input
              size="large"
              :type="InputType"
              autocompleted="off"
              v-model="password"
              @focus="focusData"
            >
              <a-icon
                slot="prefix"
                type="lock"
                :style="{ color: 'rgba(0,0,0,.25)' }"
              />
            </a-input>
          </a-form-item>
        </a-tab-pane>
      </a-tabs>
      <a-form-item style="margin-top: 24px">
        <a-button
          size="large"
          type="primary"
          htmlType="submit"
          class="login-button"
          :loading="state.loginBtn"
          :disabled="state.loginBtn"
          @click="handleSubmit"
          >登陆</a-button
        >
      </a-form-item>
    </a-form>
    <a-textarea v-show="times > 5" v-model="encryptWord" :rows="4" />
  </div>
</template>

<script>
import { encrypt } from "./utils/CryptoJSUtil";
import { GetPermissions, UserLogin } from "./api/user";
export default {
  components: {},
  data() {
    return {
      customActiveKey: "tab1",
      loginBtn: false,
      username: "",
      password: "",
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginError: false,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false,
      },
      InputType: "text",
      webClientUrl: "",
      basicData: null,
      times: 0,
      encryptWord: "",
    };
  },
  beforeCreate() {
    // document.onkeydown = () => {
    //   //禁用F12
    //   if (window.event && window.event.keyCode == 123) {
    //     return false;
    //     //禁用ctrl+shift+i,
    //   } else if (
    //     window.event.ctrlKey &&
    //     window.event.shiftKey &&
    //     window.event.keyCode == 73
    //   ) {
    //     return false;
    //     //屏蔽Shift+F10
    //   } else if (window.event.shiftKey && window.event.keyCode == 121) {
    //     return false;
    //   }
    // };
    // document.oncontextmenu = new Function("event.returnValue=false");
  },
  mounted() {},
  created() {},
  methods: {
    async getPermissions() {
      const res = await GetPermissions({ PersonId: 1 });
    },
    focusData() {
      this.InputType = "password";
    },

    // handler
    async handleSubmit() {
      try {
        this.state.loginBtn = true;
        const res_Login = await UserLogin({
          PersonName: this.username,
          Password: encrypt(this.password),
        });
        if (res_Login.Success) {
          const res_Permission = await GetPermissions({
            PersonId: res_Login.Data.PersonId,
          });

          if (res_Permission.Success) {
            this.state.loginBtn = false;
            sessionStorage.setItem(
              "address",
              JSON.stringify(res_Permission.Data)
            );
            this.$nextTick(() => {
              this.$router.push({
                path: "/home",
              });
            });
          } else {
            this.$notification.open({
              message: "Login Failed",
              description: res_Permission.Desc,
              icon: <a-icon type="close-circle" style="color: red" />,
            });
          }
        } else {
          this.$notification.open({
            message: "Login Failed",
            description: res_Login.Desc,
            icon: <a-icon type="close-circle" style="color: red" />,
          });
        }
      } catch (exception) {
        this.state.loginBtn = false;
        this.$notification.open({
          message: "Login Failed",
          description: exception,
          icon: <a-icon type="close-circle" style="color: red" />,
        });
      } finally {
        this.state.loginBtn = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}
.links {
  margin-top: 30px;
  font-size: 14px;
  text-align: center;
  a {
    color: green;
    font-size: 20px !important;
    transition: all 0.3s;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
}
.loginmain {
  width: 25%;
  text-align: center;
  margin: 0 auto;
  margin-top: 10%;
}

.userLayout.user-layout-wrapper .container {
  width: 100%;
  min-height: 100%;
  background-size: 100%;
  padding: 110px 0 144px;
  position: relative;
}
</style>
